import httpUtil from "@/utils/httpUtil";

//政策标识
export const policyIde = (params = {}) => { let data = {...params, "find[Key]": "PolicyIde" }; return httpUtil.zcpost("https://api.zhengce365.com/api/admin/site_dictionary/0", data) };

//地区查询政策机构
export const getAreaOrgan = (params) => { let data = { 'find[Type]': 2, all: 1, "find[Status]": 1, "or_find[AreaID]": "100000", ...params }; return httpUtil.zcpost("https://api.zhengce365.com/api/admin/government_organ/0", data) };

//查询政策机构
export const organ = (params = {}) => {
    let data = { 'find[Type]': 2, all: 1, "find[Status]": 1, ...params };
    //console.log(data);
    return httpUtil.zcpost("https://api.zhengce365.com/api/admin/government_organ/0", data)
};

//政策地区
export const area = (params) => { let data = {...params, "find[Status]": 1 }; return httpUtil.zcpost("https://api.zhengce365.com/api/admin/site_area/0", data) };