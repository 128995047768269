<template>
  <div class="view pa24">
    <div class="d-flex">
      <div>
        <!-- <el-button class="mr10" type="primary">添加分类</el-button> -->
        <el-input class="w220 mr10" v-model="keyWord" placeholder="文章标题" />
        <el-date-picker v-model="pickerValue" class="mr10" align="right" value-format="yyyy-MM-dd" type="daterange"
          start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" />
        <el-button type="primary" @click="fnQueryClick">查询</el-button>
      </div>
      <div style="margin-left: auto">
        <el-button type="primary" @click="showFunListModelFun">采集文章</el-button>
        <el-button type="primary" @click="$router.push('/isDrafts')">草稿箱</el-button>
        <el-button class="ml10" type="primary" @click="centerDialogVisible = true">发布文章</el-button>
      </div>
    </div>
    <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
      <template v-slot:table>
        <el-table-column type="index" align="center" label="序号" />
        <el-table-column prop="title" align="center" show-overflow-tooltip label="文章标题" />
        <el-table-column prop="originAuthor" align="center" label="来源">
          <template slot-scope="scope">
            {{ scope.row.originAuthor ? scope.row.originAuthor : scope.row.nickeName }}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" align="center" label="创建时间">
          <template slot-scope="scope">
            {{ setDataTimeSec(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="shareNum" align="center" label="分享次数" />
        <el-table-column prop="readNum" align="center" label="阅读次数" />
        <el-table-column prop="readTime" align="center" label="阅读时长" />
        <el-table-column prop="nickeName" align="center" label="创建者" />
        <el-table-column prop="isDrafts" align="center" label="发布状态">
          <template slot-scope="scope">
            <el-switch :value="scope.row.isDrafts == 1 ? false : true"
              @change="(e) => switchChange(e, scope.row.dynamicId)" active-text="发布" inactive-text="关闭">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="isTop" align="center" label="是否置顶">
          <template slot-scope="scope">
            <el-switch :value="scope.row.isTop == 1 ? true : false"
              @change="(e) => switchChange(e, scope.row.dynamicId, 'stick')" active-text="是" inactive-text="否">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="150">
          <template slot-scope="scope">
            <el-button class="mr10" type="text" @click="edit(scope.row)" v-if="scope.row.type != 3">编辑</el-button>
            <customPopconfirm confirm-button-text="确定" cancel-button-text="取消" @confirm="del(scope.row.dynamicId)"
              icon="el-icon-info" icon-color="red" title="确定要删除这篇文章吗？">
              <el-button slot="reference" style="color: #fd634e" type="text">删除</el-button>
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="发布文章" :visible.sync="centerDialogVisible" :destroy-on-close="true"
      width="50%" center>
      <postArticles @submitForm="submitForm" ref="postArticles" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="配置园企汇小程序" :visible.sync="showFunListModel" :destroy-on-close="true"
      width="50%" center>
      <commonTable :tableData="funTabel" :loading="false" :paginationVisible="false">
        <template v-slot:table>
          <el-table-column prop="name" align="center" label="功能描述" />
          <el-table-column prop="fun" align="center" label="功能">
            <template slot-scope="scope">
              <el-button type="text" @click="setNatureContent(scope)">
                <div class="el-icon-s-tools"></div>
              </el-button>
            </template>
          </el-table-column>
        </template>
      </commonTable>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showFunListModel = false">关闭</el-button>
      </span>
    </el-dialog>
    <!-- 选择文章筛选条件 -->
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="funTitle" :visible.sync="showFunModel" :destroy-on-close="true"
      width="50%" center>
      <el-form label-width="60px" v-loading="loadingNatureData">
        <el-form-item label="机构" class="organTag">
          <el-button class="button-new-tag" size="small" @click="showOrganFun">+ 机构</el-button>
          <el-tag :key="index" v-for="(row, index) in checkedOrgan" closable :disable-transitions="false"
            @close="closeOrgan(index)">
            {{ row.OrganName }}
          </el-tag>
          <el-button class="button-new-tag" v-if="checkedOrgan.length > 0" size="small" type="danger"
            @click="emptyOragn">
            清 空</el-button>
        </el-form-item>
        <el-form-item label="标识">
          <el-select v-model="companyNature.policyIde" multiple>
            <el-option :label="row.Name" :value="Number(row.ID)" v-for="(row, index) in policyIdeData" :key="index">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关键字" class="keyword">
          <el-button v-if="!showKeywordInput" class="button-new-tag" size="small" @click="showKeywordInputFun">+ 关键字
          </el-button>
          <el-input class="input-new-tag" v-if="showKeywordInput" v-model="inputKeywordValue" ref="saveTagInput"
            size="small" @keyup.enter.native="addKeyword" @blur="addKeyword">
          </el-input>
          <el-tag :key="keyword" v-for="keyword in companyNature.keyword" closable :disable-transitions="false"
            @close="closeKeyword(keyword)">
            {{ keyword }}
          </el-tag>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="showFunModel = false">关 闭</el-button>
        <el-button type="primary" @click="submitCompanyNature">保 存</el-button>
      </span>
    </el-dialog>
    <!-- 选择机构 -->
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="选择机构" :visible.sync="showOrgan" :destroy-on-close="true" width="80%"
      center>
      <div class="treeModel" v-loading="loadingOrgan">
        <div class="area">
          <el-input placeholder="请输入地区关键字进行查询" v-model="areaName" style="width: 50%">
            <el-button slot="append" icon="el-icon-search" @click="searchArea"></el-button>
          </el-input>
          <el-tree class="filter-tree" :data="areaData" ref="treeArea" :props="areaProps" :load="getAreaChildData" lazy
            @node-click="selectArea" :expand-on-click-node="false">
          </el-tree>
        </div>
        <div class="organ">
          <el-alert title="最多只能保存20条机构" type="warning" show-icon>
          </el-alert>
          <el-input placeholder="请输入机构关键字进行查询" v-model="organName" style="width: 50%;margin-top: 10px;">
          </el-input>
          <el-tree class="filter-tree" :data="organData" ref="treeOrgan" :props="organProps" show-checkbox
            @check="selectOrganFun" :filter-node-method="filterOrgan">
          </el-tree>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showOrgan = false">关 闭</el-button>
        <el-button type="primary" @click="addOrgan">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import postArticles from "./components/postArticles";
import customPopconfirm from "@/components/common/customPopconfirm";
import {
  getDynamicInfo,
  updateDynamicState,
  addDynamicInfo,
  delDynamicInfo,
  updateDynamicTop,
  setCompanyNature,
  getCompanyNature
} from "@/api/articleManagement";
import { policyIde, organ, area, getAreaOrgan } from "@/api/zhengce";
import { getDataTimeSec } from "@/utils";
export default {
  name: "customerArticles",
  components: {
    commonTable,
    postArticles,
    customPopconfirm,
  },
  data() {
    return {
      userInfo: this.$store.state.loginRoot.userInfo,
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      keyWord: "", //文章标题
      pickerValue: "", //选择时间
      centerDialogVisible: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      tableData: [],
      showFunListModel: false,
      funTabel: [
        {
          name: "首页文章列表-政策补助",
          type: "article",
        },
        { name: "首页文章列表-行业资讯", type: "policy" },
      ],
      showFunModel: false,
      funTitle: "",
      policyIdeData: [], //标识数据
      areaData: [], //地区数据
      showKeywordInput: false,
      inputKeywordValue: "",
      showOrgan: false,
      organProps: {
        label: "OrganName"
      },
      //机构类型 1国家2省,3市,4区(县)
      organData: [
        { id: 1, OrganName: "国家", children: [] },
        { id: 2, OrganName: "省", children: [] },
        { id: 3, OrganName: "市", children: [] },
        { id: 4, OrganName: "区", children: [] }
      ],

      areaProps: {
        label: "AreaName",
        children: "zones",
        isLeaf: "leaf",
      },
      areaName: "",
      organName: "",
      natureType: "",
      showCheckedOrgan: [],
      checkedOrgan: [],
      companyNature: {
        policyIde: [],
        organID: [],
        keyword: [],
      },
      submitNature: {},
      loadingNatureData: false,
      loadingOrgan: false,
      companyId: 0
    };
  },
  created() {
    this.getCompanyNature();
    this.getFunInitData();
    this.getDynamicInfoAsync();
  },
  computed: {
    setDataTimeSec() {
      return (data) => getDataTimeSec(data);
    },
  },
  watch: {
    organName(val) {
      this.$refs.treeOrgan.filter(val);
    }
  },
  methods: {
    /**---------------@method 关键字配置 ---------------*/
    /**@method 删除关键字 */
    closeKeyword(keyword) {
      let keywordData = this.companyNature.keyword;
      keywordData.splice(keywordData.indexOf(keyword), 1);
      this.$set(this.companyNature, "keyword", keywordData);
    },
    /**@method 显示输入关键字 */
    showKeywordInputFun() {
      this.showKeywordInput = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    /**@method 添加关键字 */
    addKeyword() {
      let keyword = this.inputKeywordValue;
      if (keyword) {
        let keywordData = this.companyNature.keyword;
        keywordData.unshift(keyword);
        this.$set(this.companyNature, "keyword", keywordData);
        this.showKeywordInput = false;
        this.inputKeywordValue = "";
      }
    },
    /**---------------@method 机构配置 ----------------*/
    /**@method 保存机构 */
    addOrgan() {
      let organIds = [];
      organIds = organIds.concat(this.checkedOrgan);
      organIds = organIds.concat(this.showCheckedOrgan);
      let obj = {};
      this.checkedOrgan = organIds.reduce(function (item, next) {
        obj[next.id] ? '' : obj[next.id] = true && item.push(next);
        return item;
      }, []);
      this.checkedOrgan = this.checkedOrgan.slice(0, 20)
      this.showOrgan = false;
    },
    /**@method 显示机构 */
    showOrganFun() {
      this.showOrgan = true;
      this.areaName = "";
      this.organName = "";
    },
    /**@method 清空选中的机构 */
    emptyOragn() {
      this.checkedOrgan = [];
    },
    /**@method 删除机构 */
    closeOrgan(index) {
      this.$delete(this.checkedOrgan, index)
    },
    /**@method 搜索机构 */
    filterOrgan(value, data) {
      if (!value) return true;
      return data.OrganName.indexOf(value) !== -1;
    },
    /**@method 选中机构 */
    selectOrganFun(data, checkedData) {
      //-console.log(checkedData);
      for (let check of checkedData.checkedNodes) {
        if (check.id > 4) {
          this.showCheckedOrgan.push(check);
        }
      }
    },
    /**--------------- @method 地区配置 ---------------*/
    /**@method 搜索地区 */
    searchArea() {//
      // this.$refs.treeArea.filter(val);
      this.loadingOrgan = true;
      this.getAreaData();
    },
    /**@method 获取地区数据 */
    async getAreaChildData(node, resolve) {
      if (node.level === 0) {
        let res = await area({ "find[AreaLevel]": 1, all: 1 });
        if (res.code === 0) {
          let areaData = res.rows;
          return resolve(areaData);
        } else {
          return resolve([]);
        }
      } else {
        let res = await area({ "find[TopID]": node.data.AreaCode, all: 1 });
        if (res.code === 0) {
          let areaChildData = res.rows;
          return resolve(areaChildData);
        } else {
          return resolve([]);
        }
      }
    },
    /**@method 获取地区数据 */
    async getAreaData() {
      // if (this.areaName.length >= 2) {
      let params = {};
      if (this.areaName) {
        params = {
          "search[AreaName]": this.areaName, 'find[Status]': 1
        }
      } else {
        params = {
          'find[AreaLevel]': 1, all: 1, 'find[Status]': 1
        }
      }
      let res = await area(params);
      this.areaData = res.rows;
      this.loadingOrgan = false;
      // }
    },
    /**@method 选中地区数据 */
    async selectArea(data) {
      this.loadingOrgan = true;
      let params = {};
      if (data.AreaLevel > 1) {
        params = { "front_search[AreaID]": data.AreaCode }
      } else {
        params = { "find[AreaID]": data.AreaCode }
      }
      let res = await getAreaOrgan(params);
      this.loadingOrgan = false;
      if (res.code === 0) {
        let organData = [
          { id: 1, OrganName: "国家", children: [] },
          { id: 2, OrganName: "省", children: [] },
          { id: 3, OrganName: "市", children: [] },
          { id: 4, OrganName: "区", children: [] }
        ];
        for (let row of res.rows) {
          organData[row.OrganType - 1].children.push({
            id: row.ID, OrganName: row.OrganName
          });
        }
        this.organData = organData;
      }

    },
    /**@method 初始化字典地区数据*/
    getFunInitData() {
      let that = this;
      return Promise.all([
        new Promise(async (open) => {
          let res = await policyIde();
          if (res.code === 0) //-console.log(res);
          {
            that.policyIdeData = JSON.parse(res.rows[0].Value);
          }
          open();
        }),
      ]);
    },
    async getCompanyNature() {
      this.companyId = this.userInfo.companyId;
      if (this.companyId) {
        let res = await getCompanyNature(this.companyId)
        if (res.code === 200 && res.data) {
          this.submitNature = JSON.parse(res.data)
        }
      }
    },
    /**@method 保存文章配置 */
    async submitCompanyNature() {
      if (this.companyId) {
        let params = {
          companyId: this.companyId
        };
        let organID = [];
        for (let row of this.checkedOrgan) {//获取机构id
          organID.push(row.id);
        }
        this.companyNature.organID = organID;
        this.submitNature[this.natureType] = this.companyNature;
        params.companyNature = JSON.stringify(this.submitNature)
        let res = await setCompanyNature(params);
        if (res.code === 200) {
          this.$message({
            type: "success",
            message: "配置保存成功",
          });
          this.showFunModel = false;
          this.getCompanyNature();
        } else {
          this.$message({
            type: "error",
            message: "配置保存失败",
          });
        }
      } else {
        this.$message({
          type: "error",
          message: "配置保存失败,缺少公司ID",
        });
      }

    },
    /**@method 显示文章配置内容模态框 */
    async setNatureContent(data) {
      this.funTitle = data.row.name;
      this.showFunModel = true;
      this.natureType = data.row.type;
      this.checkedOrgan = [];
      //显示筛选内容时初始化掉筛选内容
      if (this.submitNature[this.natureType]) {
        this.loadingNatureData = true;
        this.companyNature = this.submitNature[this.natureType];
        if (this.companyNature.organID && this.companyNature.organID.length > 0) {
          let res = await organ({ 'find[ID]': this.companyNature.organID.join(",") })
          this.loadingNatureData = false;
          if (res.rows && res.code === 0) {
            let checkedOrgan = [];
            for (let row of res.rows) {
              checkedOrgan.push({
                id: row.ID, OrganName: row.OrganName
              })
            }
            this.checkedOrgan = checkedOrgan
          }
        } else {
          this.loadingNatureData = false;
        }
      } else {
        this.companyNature = {
          policyIde: [],
          organID: [],
          keyword: [],
        };
      }
    },
    /**@method 显示文章配置 */
    showFunListModelFun() {
      this.showFunListModel = true;
    },
    /**@method 获取文章管理 */
    async getDynamicInfoAsync(payload) {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        isDrafts: 0,
        type: 0,
        ...payload,
      };
      try {
        this.loading = true;
        const result = await getDynamicInfo(data);
        this.loading = false;
        const {
          pageInfo: { total, list },
        } = result?.data;
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.loading = false;
        this.tableData = [];
      }
    },

    handleSizeChange(val) {
      this.currentPage =1;
      this.pageSize = val;
      this.getDynamicInfoAsync();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getDynamicInfoAsync();
    },
    /**@method 切换文章发布状态
     * @param {Boolean} e - switch值
     * @param {String} id - id
     */
    async switchChange(e, id, type) {
      let data = {
        dynamicId: id,
        isDrafts: e ? 0 : 1,
      };
      if (type) {
        data = {
          dynamicId: id,
          isTop: e ? 1 : 0,
        };
        updateDynamicTop(data)
          .then((res) => {
            if (e) {
              this.$message.success("置顶成功！");
            } else {
              this.$message.success("取消置顶！");
            }
            this.getDynamicInfoAsync();
          })
          .catch((err) => {
            if (err.code == 201) {
              //-console.log(err);
              this.$message.error(err.message);
            }
          });
      } else {
        await updateDynamicState(data);
        await this.getDynamicInfoAsync();
      }
    },
    /**@method 查询 */
    fnQueryClick() {
      this.currentPage = 1;
      let data = {
        startTime: this.pickerValue ? `${this.pickerValue[0]} 00:00:00` : "",
        endTime: this.pickerValue ? `${this.pickerValue[1]} 23:59:59` : "",
        title: this.keyWord,
      };
      this.getDynamicInfoAsync(data);
    },
    determine(data) {
      this.$refs.postArticles.submitForm(data);
    },
    /**
     * @method 发布文章提交
     * @param {Object} val - 表单值
     */
    async submitForm(val) {
      let data = {
        isDrafts: val.isDrafts ? 0 : 1,
        type: 1,
        ...val,
      };
      try {
        await addDynamicInfo(data, { showLoading: true });
        this.centerDialogVisible = false;
        await this.getDynamicInfoAsync();
      } catch (error) {
        //-console.log(error);
        this.$message.error(error.message);
      }
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    edit(val) {
      this.centerDialogVisible = true;
      const {
        dynamicId,
        companyId,
        title,
        photos,
        details,
        originAuthor,
        author,
        createUserId,
        createTime,
        isDel,
        isTop,
        isDrafts,
      } = val;
      let data = {
        dynamicId,
        companyId,
        title,
        photos,
        details,
        originAuthor,
        author,
        createUserId,
        createTime,
        isDel,
        isTop: isTop ? true : false,
        isDrafts: isDrafts ? true : false,
      };
      //-console.log(val);
      //-console.log(data);
      this.$nextTick(() => {
        this.$refs.postArticles.ruleForm = data;
        if (photos) {
          this.$refs.postArticles.fileList.push({ name: "", url: photos });
        }
      });
    },
    /**@method 删除
     * @param {String} val - 文章id
     */
    async del(val) {
      try {
        await delDynamicInfo({ dynamicId: val });
        await this.getDynamicInfoAsync();
      } catch (error) {
        //-console.log(error);
      }
    },
  },
};
</script>
<style lang="scss">
.keyword,
.organTag {
  .el-tag {
    height: 34px;
    line-height: 34px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .button-new-tag {
    margin-right: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 10px;
  }

  .input-new-tag {
    width: 90px;
    margin-right: 10px;
    vertical-align: bottom;
  }
}
</style>
<style lang="scss" scoped>
.treeModel {
  display: flex;

  .area {
    width: 50%;
  }

  .organ {
    width: 50%;
  }
}

.filter-tree {
  margin-top: 20px;
}
</style>